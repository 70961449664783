import { Component, Vue } from 'vue-property-decorator';

@Component({})
export class Gopage extends Vue {
  handleCommand(option: any) {
    switch (option) {
      case '首页':
        this.$router.push('/index');
        break;
      case '解决方案':
        this.$router.push('/solution');
        break;
      case '产品中心':
        this.$router.push('/productCenter');
        break;
      case '服务支持':
        this.$router.push('/serviceSupport');
        break;
      case '关于我们':
        this.$router.push('/aboutUs');
        break;
      case '立即体验':
        this.$router.push('/businessRegistration');
        break;
    }
    window.scrollTo({ top: 0 });
  }

  private handleClickRoute(e: any) {
    switch (e.label) {
      case '首页':
        this.$router.push('/index');
        break;
      case '解决方案':
        this.$router.push('/solution');
        break;
      case '产品中心':
        this.$router.push('/productCenter');
        break;
      case '服务支持':
        this.$router.push('/serviceSupport');
        break;
      case '关于我们':
        this.$router.push('/aboutUs');
        break;
    }
  }

  private icpOpen() {
    window.open('https://beian.miit.gov.cn', '_blank');
  }

  openPic(url: any) {
    window.open(url, '_blank');
  }
}
